.button {
    transition: all 0.25s ease;
    width: 100%;
    height: 50px;
    background-color: #4facfe;
    border: none;
    font-size: 17px;
    text-align: center;
    font-weight: 700;
    color: #fff;
    margin-top: 15px;
    padding: 12px;
    cursor: default;
    border-radius: 25px;
}

.button#circle {
    width: 50px;
    border-radius: 25px;
    background-color: white;
    color: black;
    margin-left: 10px;
}

.button#destructive {
    color: #d82828;
}

.button:hover {
    background-color: #e9e9e9;
}

.button:active {
    background-color: #dbdbdb;
}

.button:disabled {
    background-color: #f6f6f6;
    color: gray;
    box-shadow: none;
    opacity: 0.6;
}

.button#loading {
    box-shadow: none;
}

.button-loadingContainer {
    text-align: center;
}
