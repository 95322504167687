select.SelectField-select {
    height: 50px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    color: black;
    float: right;
    margin: 0;
    padding: 0;
    text-align: right;
    border-radius: 0;
    -webkit-appearance: none;
    margin-right: 20px;
}

select.SelectField-select:disabled {
    color: black;

    opacity: 1; /* Needed for Safari */
    -webkit-text-fill-color: black; /* Needed for Safari */
}