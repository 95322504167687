.content {
  margin: 0;
  padding-top: 0;
  width: 90%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

.wrapper {
  padding-top: 70px;
}

.titlebar {
  width: 90%;
  height: 100px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.titlebar-title {
  margin: 0;
  text-align: left;
  line-height: 100px;
  font-size: 30px;
  font-weight: 600;
}

.navbar {
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid #dddddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

.navbar-menu-container {
  display: grid;
  width: 288px;
  height: 46px;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  border-radius: 35px;
  margin-top: 12px;
}

.navbar-menu-item {
  transition: all 0.2s;
  width: 46px;
  height: 46px;
  background-color: transparent;
  text-align: center;
  line-height: 50px;
  box-sizing: border-box;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 34px;
}

.navbar-menu-item:hover {
  background-color: #e5e5e5;
}

.navbar-menu-item:active {
  background-color: #e0e0e0;
}

.navbar-menu-item#selected {
  background-color: #e5e5e5;
}

.navbar-menu-item#selected:hover {
  background-color: #e5e5e5;
}

input[type='text'] {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  margin-bottom: 20px;
  background-color: #f1f1f2;
  font-size: 17px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  -webkit-appearance: none;
  border: none;
}

input[type="checkbox"], input[type="radio"] {
  width: 20px;
  height: 20px;
}

input[type="date"] {
  -webkit-appearance: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

::placeholder {
  color: gray;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  height: 250px;
  border: none;
  border-radius: 25px;
  margin-bottom: 20px;
  box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75), inset 5px 5px 10px 0 #D0DBE7;
  background-color: transparent;
  font-size: 17px;
  font-weight: bolder;
  padding-left: 25px;
  padding-right: 25px;
  color: #567DA8;
  text-shadow: 0 1px 0 white;
  padding-top: 20px;
  -webkit-appearance: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

textarea.text{
  background-color: #f1f1f2;
}

select, input[type=button], input[type=submit], input[type=reset] {
  transition: box-shadow 0.25s ease;
  width: 100%;
  height: 50px;
  background-color: #E9EDF0;
  border: none;
  margin-bottom: 20px;
  font-size: 18px;
  color: #587FAA;
  font-weight: bold;
  border-radius: 25px;
  text-shadow: 0 1px white;
  box-shadow: 10px 10px 21px -8px #B6C6D4, -10px -10px 20px 0 rgba(255, 255, 255, 0.57);
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover {
  box-shadow: 10px 17px 21px -8px #97a6b3, -10px -10px 20px 0 rgba(255, 255, 255, 0.8);
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active {
  box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75), inset 5px 5px 10px 0 #D0DBE7;
}

input[type=button]:disabled, input[type=submit]:disabled, input[type=reset]:disabled {
  background-color: white;
  box-shadow: none;
  opacity: 0.6;
}

.loadingSpinner {
  width: 60px;
  height: 60px;

  position: relative;
  margin: 100px auto;
}

.loadingSpinner-small {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 11px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #0c9dab;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}

.editField-container {
  width: 100%;
  height: 50px;
  float: left;
  border-radius: 25px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
}

.editField-label {
  width: calc(50% - 20px);
  font-size: 18px;
  color: gray;
  font-weight: 500;
  margin-top: 0;
  user-select: none;
  cursor: default;
  line-height: 50px;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 20px;
}

input.editField-textField {
  width: calc(50% - 20px);
  height: 50px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  color: black;
  float: right;
  margin: 0;
  padding: 0;
  text-align: right;
  border-radius: 0;
  margin-right: 20px;
}

input.editField-textField:disabled {
  color: black;

  opacity: 1; /* Needed for Safari */
  -webkit-text-fill-color: black; /* Needed for Safari */
}

.spacer20 {
  width: 100%;
  height: 20px;
  float: left;
}

.profile-profilePicture {
  width: 180px;
  height: 180px;
  background-color: #0c9dab;
  border: 8px solid white;
  border-radius: 100px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.14);
  margin-left: auto;
  margin-right: auto;
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.profile-profilePicture-editButton {
  width: 180px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 90px;
  background-image: url("./icons/file-upload-solid.svg");
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
}

.heading-small {
  font-size: 26px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.heading-small#leading {
  text-align: left;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  color: gray;
  margin-top: 10px;
}

.messageContainer {
  width: 100%;
  margin-top: 80px;
}

.iconCircle {
  width: 140px;
  height: 140px;
  margin-left: auto;
  margin-right: auto;
  background-color: #0c9dab;
  border: none;
  margin-bottom: 40px;
  color: #587FAA;
  border-radius: 70px;
  text-shadow: 0 1px white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.14);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80px 80px;
}

p.text {
  font-size: 18px;
  color: gray;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-shadow: 0 1px white;
  margin-bottom: 30px;
  user-select: none;
  cursor: default;
}

p.bodyText {
  font-size: 18px;
  color: black;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  text-shadow: 0 1px white;
  margin-bottom: 30px;
  user-select: none;
  cursor: default;
}

div.errorContainer {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 25px;
  background-color: #f5f5f5;
  float: left;
}

p.errorText {
  font-size: 18px;
  color: #d82828;
  font-weight: 500;
  text-align: left;
  text-shadow: 0 1px white;
  user-select: none;
  cursor: default;
  margin-top: 0;
  margin-bottom: 10px;
}

p.errorText#inset {
  font-size: 15px;
  color: gray;
  font-weight: 500;
  text-align: left;
  text-shadow: 0 1px white;
  user-select: none;
  cursor: default;
  margin-top: 0;
  margin-bottom: 0;
}

.importItem-cell {
  transition: all 0.2s;
  width: 100%;
  height: 140px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
}

.importItem-cell:hover {
  background-color: #e9e9e9;
}

.importItem-cell-left {
  width: 140px;
  height: 140px;
  position: relative;
}

.importItem-cell-image {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  background-color: #0c9dab;
  border-radius: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.importItem-cell-profileImage {
  position: absolute;
  top: 78px;
  right: 20px;
  bottom: 20px;
  left: 78px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 44px;
  height: 44px;
  background-color: #0c9dab;
  border: 3px solid white;
  border-radius: 100px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.14);
}

.importItem-cell-right {
  display: flex;
  flex-direction: column;
  align-items: start; /* Vertical */
  justify-content: center; /* Horizontal */
}

.importItem-cell-label {
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 3px;
}

.footer {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 20px;
  line-height: 30px;
  color: gray;
}

a {
  text-decoration: none;
  color: #0c9dab;
}

a:hover {
  text-decoration: underline;
}

.linkButton {
  text-decoration: none;
  color: #0c9dab;
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
}

.linkButton:hover {
  text-decoration: underline;
}

.importDetail-hero {
  width: 100%;
  height: 320px;
  background-color: #0c9dab;
  border-radius: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.importDetail-cell {
  transition: all 0.2s;
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
}

.fill-remaining-space {
  flex: auto;
}

.importDetail-cell-left {
  width: 50px;
  height: 50px;
}

.importDetail-cell-image {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  margin-left: 10px;
  margin-top: 10px;
}

.importDetail-cell-right {
  display: flex;
  flex-direction: row;
  align-items: start; /* Vertical */
  justify-content: center; /* Horizontal */
  margin-right: 15px;
}

.importDetail-cell-label {
  justify-content: center;
  margin: 0;
  padding: 0;
  line-height: 49px;
  color: gray;
  font-weight: 600;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
  margin-bottom: 30px;
}

.chat-profileBanner {
  width: 90%;
  max-width: 800px;
  height: 68px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e2e2e2;
  position: fixed;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: -160px;
}

.profileBanner {
  width: 100%;
  height: 98px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.profileBanner-image {
  width: 90px;
  height: 90px;
  background-color: #0c9dab;
  border: 4px solid white;
  border-radius: 100px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.14);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.chat-profileBanner-image {
  width: 60px;
  height: 60px;
  background-color: #0c9dab;
  border: 4px solid white;
  border-radius: 100px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.14);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.chat-profileBanner-left {
  width: 80px;
  height: 80px;
}

.profileBanner-left {
  width: 113px;
  height: 113px;
}

.profileBanner-right {
  display: flex;
  flex-direction: column;
  align-items: start; /* Vertical */
  justify-content: center; /* Horizontal */
}

.profileBanner-starsContainer {
  background-color: white;
}

.profileBanner-star {
  width: 20px;
  height: 20px;
  background-image: url("./icons/star.svg");
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
  margin-right: 4px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.profileBanner-star#fill {
  filter: invert(75%) sepia(92%) saturate(1191%) hue-rotate(343deg) brightness(180%) contrast(93%);
}

.profileBanner-label {
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-bottom: 3px;
}

.buttonArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end; /* Vertical */
  justify-content: center; /* Horizontal */
}

.buttonArea-executeButton {
  transition: all 0.2s;
  width: 100px;
  height: 100px;
  border-radius: 56px;
  border: 3px solid #0c9dab;
  box-shadow: 0 1px 5px rgba(26, 191, 207, 0.6);
  margin-left: 50px;
  margin-right: 50px;
  background-image: url("./icons/thumbsup.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
}

.buttonArea-executeButton:hover {
  background-color: rgba(12, 157, 171, 0.1);
  box-shadow: 0 3px 10px rgba(26, 191, 207, 0.7);
}

.buttonArea-executeButton:active {
  background-color: rgba(12, 157, 171, 0.2);
  box-shadow: 0 3px 10px rgba(26, 191, 207, 0.9);
}

.buttonArea-accessoryButton {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #f5f5f5;
  margin-top: 46px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 26px;
}

.chatBubble-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.chatBubble {
  background-color: red;
  padding: 15px;
  border-radius: 30px;
  max-width: 420px;
}

.chatBubble-image {
  width: 80%;
  height: 300px;
  border-radius: 30px;
  max-width: 420px;
  max-height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #ececec;
}

.chatTextField-container {
  width: 100%;
  max-width: 800px;
  background-color: white;
  border-top: 1px solid #e2e2e2;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
}

.chatTextField-sendButton {
  transition: all 0.2s;
  width: 50px;
  height: 50px;
  max-height: 50px;
  min-width: 50px;
  background-color: #3d3d3d;
  margin-top: 20px;
  border: none;
  border-radius: 50%;
  margin-left: 15px;
  background-image: url("./icons/arrowup.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  padding: 0;
}
.chatTextField-sendButton:disabled {
  background-color: #cacaca;
}

.chatTextField-sendButton:hover {
  background-color: #4f4f4f;
}

.chatTextField-sendButton:active {
  background-color: #393939;
}

.chatTextField-imageButton {
  transition: all 0.2s;
  width: 50px;
  height: 50px;
  max-height: 50px;
  min-width: 50px;
  background-color: #3d3d3d;
  margin-top: 20px;
  border: none;
  border-radius: 50%;
  margin-right: 15px;
  background-image: url("./icons/plus.multiuse.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  padding: 0;
}
.chatTextField-imageButton:disabled {
  background-color: #cacaca;
}

.chatTextField-imageButton:hover {
  background-color: #4f4f4f;
}

.chatTextField-imageButton:active {
  background-color: #393939;
}

.hiddenUpload {
  display: none;
}

.chevron {
  width: 14px;
  height: 14px;
  background-image: url("./icons/chevron.right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 18px;
  margin-left: 5px;
}

.dateField {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  margin-bottom: 20px;
  background-color: #f1f1f2;
  font-size: 17px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  border: none;
  outline: none;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0c9dab !important;
}

.react-calendar {
  border: none !important;
  background: rgba(255, 255, 255, 0.9) !important;
  -webkit-backdrop-filter: blur(25px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 10px;
}

.react-calendar__navigation__label__labelText {
  font-size: 17px !important;
  font-weight: bold;
}

.react-calendar button {
  min-width: 44px;
  min-height: 44px;
  border-radius: 14px;
  font-size: 17px;
}

.react-calendar button:enabled:hover {
  background: #ececec !important;
}

.react-calendar__tile--active {
  background: #0c9dab !important;
}
